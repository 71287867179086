<template>
  <b-collapse
    tag="ul"
    :class="className"
    :visible="open"
    :id="idName"
    :accordion="accordianName"
  >
    <span v-for="(item, index) in items" :key="index">
      <li
        :class="
          item.is_heading
            ? 'iq-menu-title'
            : activeLink(item) && item.children
            ? 'active'
            : activeLink(item)
            ? 'active'
            : ''
        "
        v-if="checkPermissions(item)"
      >
        <i v-if="item.is_heading" class="ri-subtract-line" />
        <span v-if="item.is_heading">{{ $t(item.name) }}</span>
        <router-link
          :to="item.link"
          v-if="!item.is_heading"
          :class="`iq-waves-effect ${
            activeLink(item) && item.children
              ? 'active'
              : activeLink(item)
              ? 'active'
              : ''
          }`"
          v-b-toggle="item.name"
        >
          <i :class="item.icon" v-if="item.is_icon_class" />
          <template v-else v-html="item.icon"> </template>
          <span>{{ $t(item.name) }}</span>
          <i
            v-if="item.children"
            class="ri-arrow-right-s-line iq-arrow-right"
          />
          <small v-html="item.append" :class="item.append_class" />
        </router-link>
        <List
          :miniSidebar="miniSidebar"
          v-if="item.children"
          :items="item.children"
          :open="
            item.link.name !== '' && activeLink(item) && item.children
              ? true
              : !!(item.link.name !== '' && activeLink(item))
          "
          :idName="item.name"
          :accordianName="`sidebar-accordion-${item.class_name}`"
          :className="`iq-submenu ${item.class_name}`"
        />
      </li>
      <li
        :class="
          item.is_heading
            ? 'iq-menu-title'
            : activeLink(item) && item.children
            ? 'active'
            : activeLink(item)
            ? 'active'
            : ''
        "
        v-if="item.title === 'dashboard' && !user.roles.includes('GUEST')"
      >
        <i v-if="item.is_heading" class="ri-subtract-line" />
        <span v-if="item.is_heading">{{ $t(item.name) }}</span>
        <router-link
          :to="item.link"
          v-if="!item.is_heading"
          :class="`iq-waves-effect ${
            activeLink(item) && item.children
              ? 'active'
              : activeLink(item)
              ? 'active'
              : ''
          }`"
          v-b-toggle="item.name"
        >
          <i :class="item.icon" v-if="item.is_icon_class" />
          <template v-else v-html="item.icon"> </template>
          <span>{{ $t(item.name) }}</span>
          <i
            v-if="item.children"
            class="ri-arrow-right-s-line iq-arrow-right"
          />
          <small v-html="item.append" :class="item.append_class" />
        </router-link>
        <List
          :miniSidebar="miniSidebar"
          v-if="item.children"
          :items="item.children"
          :open="
            item.link.name !== '' && activeLink(item) && item.children
              ? true
              : !!(item.link.name !== '' && activeLink(item))
          "
          :idName="item.name"
          :accordianName="`sidebar-accordion-${item.class_name}`"
          :className="`iq-submenu ${item.class_name}`"
        />
      </li>
    </span>
  </b-collapse>
</template>
<script>
import List from "./ListStyle1";
import { xray } from "../../../config/pluginInit";
import { mapGetters } from "vuex";
export default {
  name: "List",
  computed: {
    ...mapGetters({ user: "Auth/getCurrentUser" }),
  },
  props: {
    items: Array,
    className: { type: String, default: "iq-menu" },
    horizontal: Boolean,
    open: { type: Boolean, default: false },
    idName: { type: String, default: "sidebar" },
    accordianName: { type: String, default: "sidebar" },
    miniSidebar: Function,
  },
  components: {
    List,
  },
  mounted() {
    const eles = document.getElementsByClassName("iq-waves-effect");
    Array.from(eles).forEach((element) => {
      element.addEventListener("click", () => {
        document.querySelector(".wrapper-menu").classList.toggle("open");
        document.querySelector("body").classList.remove("sidebar-main");
      });
    });

    document.querySelector("#main-content *").addEventListener("click", () => {
      document.querySelector(".wrapper-menu").classList.toggle("open");
      document.querySelector("body").classList.remove("sidebar-main");
    });
  },
  methods: {
    activeLink(item) {
      return xray.getActiveLink(item, this.$route.name);
    },
    checkPermissions(item) {
      let res = false;
      if (item.title == "guide-list" && this.user.roles.includes("ADMIN")) {
        item.link = "/" + this.$i18n.locale + "/guide/admin";
      }
      if (item.title === "dashboard") {
        return false;
      }
      if (!item.role.length) {
        return true;
      }
      if (
        this.user.roles.includes("ADMIN") &&
        item.title == "guide" &&
        !item.isHeading
      ) {
        return false;
      }
      this.user.permissions.forEach((e) => {
        for (let i = 0; i < item.role.length; i++) {
          if (e.name === item.role[i]) {
            res = true;
            return;
          }
        }
      });
      return res;
    },
  },
};
</script>
