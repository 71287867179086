<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary">{{ tableTitle }}</h4>
          </template>
          <template v-slot:headerAction>
            <div class="row" style="padding: 10px">
              <b-form-input
                id="filter-input"
                class="col m-2"
                style="width: 300px; height: 35px"
                v-model="filter"
                type="search"
                :placeholder="$t('inputs.search')"
              ></b-form-input>
              <b-button
                class="col m-2"
                variant="primary"
                style="white-space: nowrap"
                :to="'/' + $i18n.locale + addBtn.link"
                v-if="user.roles.includes('ADMIN')"
                ><i class="fas fa-plus"></i> {{ addBtn.text }}</b-button
              >
            </div>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col md="12" class="table-responsive">
                <b-table
                  :per-page="20"
                  :current-page="currentPage"
                  bordered
                  hover
                  :items="rows"
                  id="my-table"
                  :fields="cols"
                  foot-clone
                >
                  <template v-slot:cell(institution)="data">
                    <span>{{ data.item.institution.name }}</span>
                  </template>
                  <template v-slot:cell(services)="data">
                    <li v-for="ser of data.item.services" :key="ser.id">
                      {{ ser.name }}
                    </li>
                  </template>
                  <template v-slot:cell(level)="data">
                    <div class="text-center mt-3">
                      <span v-if="data.item.level == 4">
                        <i class="fas fa-star star-gold star" />
                        <i class="fas fa-star star-gold star" />
                        <i class="fas fa-star star-gold star" />

                        <i class="fas fa-crown star-gold star" />
                      </span>
                      <span v-if="data.item.level == 3">
                        <i class="fas fa-star star-gold star" />
                        <i class="fas fa-star star-gold star" />
                        <i class="fas fa-star star-gold star" />

                        <i class="fas fa-crown star" />
                      </span>
                      <span v-if="data.item.level == 2">
                        <i class="fas fa-star star-gold star" />
                        <i class="fas fa-star star-gold star" />
                        <i class="fas fa-star star" />

                        <i class="fas fa-crown star" />
                      </span>
                      <span v-if="data.item.level == 1">
                        <i class="fas fa-star star-gold star" />
                        <i class="fas fa-star star" />
                        <i class="fas fa-star star" />

                        <i class="fas fa-crown star" />
                      </span>
                    </div>
                  </template>
                  <template v-slot:cell(action)="data">
                    <b-button
                      variant=" iq-bg-success mr-1 mt-1"
                      size="sm"
                      v-if="user.roles.includes('ADMIN')"
                      @click="edit(data.item.id)"
                      ><i class="ri-ball-pen-fill m-0"></i
                    ></b-button>
                    <b-button
                      variant=" iq-bg-danger mr-1 mt-1"
                      size="sm"
                      v-if="user.roles.includes('ADMIN')"
                      @click="onRemove(data.item)"
                      ><i class="ri-delete-bin-line m-0"></i
                    ></b-button>
                    <!-- TODO: Afficher le bouton démarrer si le service de l'utilisateur se trouve dans la liste des services du questionnaire -->
                    <b-button
                      variant=" iq-bg-primary mr-1 mt-1"
                      size="sm"
                      title="Demarrer le questionnaire"
                      v-if="checkServices(user.service, data.item.services)"
                      @click="assessmentExist(data.item.id)"
                      ><i class="ri-play-line m-0"></i
                    ></b-button>
                  </template>
                </b-table>
                <b-pagination
                  class="mt-3 pull-right"
                  v-model="currentPage"
                  :total-rows="rows.length"
                  :per-page="20"
                  aria-controls="my-table"
                ></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal id="modal-1" title="Attention!" @ok="remove(item)">
      <p class="my-4">
        Etes vous sure de vouloir supprimer le questionnaire
        <span class="bold text-danger">{{ modalMsg }}</span> ?
      </p>
    </b-modal>
  </b-container>
</template>
<script>
import { mapGetters } from "vuex";
import { xray } from "../../config/pluginInit";
import ApiService from "../../Utils/axios";

// import starGray from "../icons/star-gray.svg";
// import laurelGray from "../icons/laurel-gray.svg";
// import laurel from "../icons/laurel.svg";
// import star from "../icons/star.svg";
export default {
  name: "UiDataTable",
  props: {
    addBtn: Object,
    tableTitle: String,
    rows: Array,
    cols: Array,
    fetchList: Function,
  },
  computed: {
    ...mapGetters({ user: "Auth/getCurrentUser" }),
  },
  mounted() {
    xray.index();
    console.log(this.rows);
  },
  methods: {
    default() {
      return {
        id: this.rows.length,
      };
    },
    checkServices(userS, itemS) {
      let res = false;
      itemS.forEach((e) => {
        if (e.id === userS.id) {
          res = true;
          return;
        }
      });
      return res;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    edit(id) {
      this.$router.push("/" + this.$i18n.locale + "/survey/edit/" + id);
    },
    onRemove(item) {
      this.item = item;
      this.modalMsg = item.title;
      this.$bvModal.show("modal-1");
    },
    remove(item) {
      let api = new ApiService();
      api
        .delete("/api/survey/" + item.id)
        .then((res) => {
          this.fetchList();
          this.$store.dispatch("Alert/activate", {
            text: "Questionnaire supprimé avec succée",
            type: "success",
          });
        })
        .catch((err) => {
          if (err.response.data.code && err.response.data.code === "23000") {
            this.$store.dispatch("Alert/activate", {
              text: "Le questionnaire ne peut pas être supprimé parce qu'il est utilisé par d'autres entités du système",
              type: "danger",
            });
          } else {
            this.$store.dispatch("Alert/activate", {
              text: "Une erreur est survenue, veuillez réessayer ultérieurement ou contacter l'administration.",
              type: "danger",
            });
          }
        });
    },
    async assessmentExist(survey_id) {
      const api = new ApiService();
      const exist = await api.get(`/api/survey/${survey_id}/exist`);
      if (exist.data.assessment_id) {
        this.$router.push(
          "/" +
            this.$i18n.locale +
            `/assessment/start?survey_id=${survey_id}&assessment_id=${exist.data.assessment_id}`
        );
      } else {
        api
          .post(`/api/assessment`, {
            survey_id: survey_id,
          })
          .then((res) => {
            this.$router.push(
              "/" +
                this.$i18n.locale +
                `/survey/start?survey_id=${survey_id}&assessment_id=${res.data.id}`
            );
          })
          .catch((e) => console.log(e.response));
      }
    },
  },
  data() {
    return {
      // starGray: starGray,
      // star: star,
      // laurel: laurel,
      // laurelGray: laurelGray,

      currentPage: 1,
      filter: "",
      modalMsg: "",
      item: null,
    };
  },
};
</script>

<style>
.star {
  width: 18px;
}

.laurel {
  width: 27px;
}
</style>
