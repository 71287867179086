<template>
  <div>
    <div id="show-overlay"></div>
    <Loader />
    <div class="wrapper">
      <!-- Sidebar  -->
      <SideBarStyle1
        v-if="user.roles.includes('MANAGER') || user.roles.includes('STANDARD')"
        :items="verticalMenu"
        :horizontal="horizontal"
        @toggle="sidebarMini"
      />
      <div
        id="content-page"
        :class="
          user.roles.includes('MANAGER') || user.roles.includes('STANDARD')
            ? 'content-page'
            : 'guest-page'
        "
      >
        <!-- :style="
          user.institution.background
            ? `background-image: url(${apiUrl +
                '/storage/' +
                user.institution.background
                  .path}); background-size: cover;background-attachment: fixed;`
            : '' " -->

        <!-- <img
          v-if="user.institution.background"
          class="position-fixed vh-100"
          :src="apiUrl + '/storage/' + user.institution.background.path"
        /> -->
        <!-- TOP Nav Bar -->
        <NavBarStyle1
          title="Dashboard"
          :homeURL="{ name: 'dashboard1.home' }"
          @toggle="sidebarMini"
          :horizontal="horizontal"
          :items="horizontalMenu"
        >
          <template slot="responsiveRight">
            <ul class="navbar-nav ml-auto navbar-list">
              <!-- <li class="nav-item">
                <a class="search-toggle iq-waves-effect language-title" href="#"
                  ><img
                    :src="selectedLang.image"
                    alt="img-flaf"
                    class="img-fluid mr-1"
                    style="height: 16px; width: 16px;"/>
                  {{ selectedLang.title }} <i class="ri-arrow-down-s-line"></i
                ></a>
                <div class="iq-sub-dropdown">
                  <a
                    class="iq-sub-card"
                    href="javascript:void(0)"
                    v-for="(lang, i) in langsOptions"
                    :key="`Lang${i}`"
                    @click="langChange(lang)"
                  >
                    <img
                      :src="lang.image"
                      alt="img-flaf"
                      class="img-fluid mr-2"
                    />{{ lang.title }}
                  </a>
                </div>
              </li> -->
              <!-- <li class="nav-item iq-full-screen">
                <a href="#" class="iq-waves-effect" id="btnFullscreen"
                  ><i class="ri-fullscreen-line"></i
                ></a>
              </li> -->
              <!-- <li class="nav-item">
                <a href="#" class="search-toggle iq-waves-effect">
                  <i class="ri-notification-3-fill"></i>
                  <span class="bg-danger dots"></span>
                </a>
                <div class="iq-sub-dropdown">
                  <div class="iq-card shadow-none m-0">
                    <div class="iq-card-body p-0 ">
                      <div class="bg-primary p-3">
                        <h5 class="mb-0 text-white">
                          {{ $t("nav.allNotifications")
                          }}<small class="badge  badge-light float-right pt-1"
                            >4</small
                          >
                        </h5>
                      </div>
                      <a
                        href="#"
                        class="iq-sub-card"
                        v-for="(item, index) in notification"
                        :key="index"
                      >
                        <div class="media align-items-center">
                          <div class="">
                            <img
                              class="avatar-40 rounded"
                              :src="item.image"
                              alt="img"
                            />
                          </div>
                          <div class="media-body ml-3">
                            <h6 v-if="user" class="mb-0 ">{{ user.name }}</h6>
                            <small class="float-right font-size-12">{{
                              item.date
                            }}</small>
                            <p class="mb-0">
                              {{ item.description.substring(0, 40) + "..." }}
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </li> -->
              <!-- <li class="nav-item">
                <a href="#" class="search-toggle iq-waves-effect">
                  <i class="ri-mail-open-fill"></i>
                  <span class="bg-primary count-mail"></span>
                </a>
                <div class="iq-sub-dropdown">
                  <div class="iq-card shadow-none m-0">
                    <div class="iq-card-body p-0 ">
                      <div class="bg-primary p-3">
                        <h5 class="mb-0 text-white">
                          {{ $t("nav.allMessages")
                          }}<small class="badge  badge-light float-right pt-1"
                            >5</small
                          >
                        </h5>
                      </div>
                      <a
                        href="#"
                        class="iq-sub-card"
                        v-for="(item, index) in message"
                        :key="index"
                      >
                        <div class="media align-items-center">
                          <div class="">
                            <img
                              class="avatar-40 rounded"
                              :src="item.image"
                              alt="img"
                            />
                          </div>
                          <div class="media-body ml-3">
                            <h6 class="mb-0 ">{{ item.name }}</h6>
                            <small class="float-left font-size-12">{{
                              item.date
                            }}</small>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </li> -->

              <li class="nav-item">
                <div class="d-inline-block text-center p-3">
                  <a
                    class="iq-bg-danger iq-sign-btn"
                    href="javascript:void(0)"
                    @click="logout"
                    role="button"
                    >{{ $t("nav.user.signout") }}
                    <i class="ri-login-box-line ml-2"></i
                  ></a>
                </div>
              </li>
            </ul>
          </template>
          <template slot="right">
            <ul class="navbar-list">
              <li>
                <a class="d-flex align-items-center">
                  <!-- <img
                    :src="userProfile"
                    class="img-fluid rounded mr-3"
                    alt="user"
                  />
                  <div class="caption">
                    <h6 v-if="user" class="mb-0 line-height">
                      {{ user.name }}
                    </h6>
                    <span class="font-size-12">{{
                      $t("nav.user.available")
                    }}</span>
                  </div> -->

                  <b-avatar
                    variant="primary"
                    :text="userName"
                    size="3rem"
                  ></b-avatar>
                </a>
                <!-- <div class="iq-sub-dropdown iq-dropdown">
                  <div class="iq-card shadow-none m-0">
                    <div class="iq-card-body p-0 ">
                      <div class="bg-primary p-3">
                        <h5 v-if="user" class="mb-0 text-white line-height">
                          Bonjour {{ user.name }}
                        </h5>
                        <span class="text-white font-size-12">{{
                          $t("nav.user.available")
                        }}</span>
                      </div>
                      <a href="#" class="iq-sub-card iq-bg-primary-hover">
                        <div class="media align-items-center">
                          <div class="rounded iq-card-icon iq-bg-primary">
                            <i class="ri-file-line"></i>
                          </div>
                          <div class="media-body ml-3">
                            <h6 class="mb-0 ">
                              {{ $t("nav.user.profileTitle") }}
                            </h6>
                            <p class="mb-0 font-size-12">
                              {{ $t("nav.user.profileSub") }}
                            </p>
                          </div>
                        </div>
                      </a>
                      <a
                        href="#"
                        class="iq-sub-card iq-bg-primary-success-hover"
                      >
                        <div class="media align-items-center">
                          <div class="rounded iq-card-icon iq-bg-success">
                            <i class="ri-profile-line"></i>
                          </div>
                          <div class="media-body ml-3">
                            <h6 class="mb-0 ">
                              {{ $t("nav.user.profileEditTitle") }}
                            </h6>
                            <p class="mb-0 font-size-12">
                              {{ $t("nav.user.profileEditSub") }}
                            </p>
                          </div>
                        </div>
                      </a>
                      <a
                        href="#"
                        class="iq-sub-card iq-bg-primary-danger-hover"
                      >
                        <div class="media align-items-center">
                          <div class="rounded iq-card-icon iq-bg-danger">
                            <i class="ri-account-box-line"></i>
                          </div>
                          <div class="media-body ml-3">
                            <h6 class="mb-0 ">
                              {{ $t("nav.user.accountSettingTitle") }}
                            </h6>
                            <p class="mb-0 font-size-12">
                              {{ $t("nav.user.accountSettingSub") }}
                            </p>
                          </div>
                        </div>
                      </a>
                      <a
                        href="#"
                        class="iq-sub-card iq-bg-primary-secondary-hover"
                      >
                        <div class="media align-items-center">
                          <div class="rounded iq-card-icon iq-bg-secondary">
                            <i class="ri-lock-line"></i>
                          </div>
                          <div class="media-body ml-3">
                            <h6 class="mb-0 ">
                              {{ $t("nav.user.privacySettingTitle") }}
                            </h6>
                            <p class="mb-0 font-size-12">
                              {{ $t("nav.user.privacySettingSub") }}
                            </p>
                          </div>
                        </div>
                      </a>
                      <div class="d-inline-block w-100 text-center p-3">
                        <a
                          class="iq-bg-danger iq-sign-btn"
                          href="javascript:void(0)"
                          @click="logout"
                          role="button"
                          >{{ $t("nav.user.signout")
                          }}<i class="ri-login-box-line ml-2"></i
                        ></a>
                      </div>
                    </div>
                  </div>
                </div> -->
              </li>
            </ul>
          </template>
        </NavBarStyle1>
        <!-- TOP Nav Bar END -->

        <Alert />
        <transition
          name="router-anim"
          :enter-active-class="`animated ${animated.enter}`"
          mode="out-in"
          :leave-active-class="`animated ${animated.exit}`"
        >
          <div id="main-content">
            <router-view />
          </div>
        </transition>

        <b-modal
          centered
          size="lg"
          ok-only
          id="intro-modal"
          :title="$t('greetingTitle')"
          header-class="justify-content-center"
        >
          <p>
            {{ $t("greetingMessage") }}
          </p>
        </b-modal>
        <FooterStyle1>
          <template v-slot:left>
            <li class="list-inline-item">
              <a href="#">Privacy Policy</a>
            </li>
            <li class="list-inline-item">
              <a href="#">Terms of Use</a>
            </li>
          </template>
          <template v-slot:right>
            Copyright 2021 - 2023, &nbsp;<a href="#">Euromedi International,</a>
            All Rights Reserved.
          </template>
        </FooterStyle1>
      </div>
    </div>
  </div>
</template>
<script>
import FooterStyle1 from "../components/xray/footer/FooterStyle1";
import Loader from "../components/xray/loader/Loader";
import SideBarStyle1 from "../components/xray/sidebars/SideBarStyle1";
import NavBarStyle1 from "../components/xray/navbars/NavBarStyle1";
import SideBarItems from "../FackApi/json/SideBar";
import HorizontalItems from "../FackApi/json/HorizontalMenu";
import profile from "../assets/images/user/1.jpg";
import loader from "../assets/images/logo.png";
import Alert from "../components/xray/alerts/Alert";
import { xray } from "../config/pluginInit";
import { Users } from "../FackApi/api/chat";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Layout1",
  components: {
    Loader,
    SideBarStyle1,
    NavBarStyle1,
    Alert,
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.beforeunloadFn);
  },
  mounted() {
    let second = this.user.name.split(" ")[1]
      ? this.user.name.split(" ")[1][0]
      : "";
    this.userName = this.user.name.split(" ")[0][0] + second;

    this.updateRadio();
    xray.mainIndex();
    this.changeColor({ primary: "#054690", primaryLight: "#054690" });
    console.log(this.user);

    if (
      !this.user.roles.includes("ADMIN") &&
      !this.user.roles.includes("MANAGER")
    ) {
      if (!JSON.parse(localStorage.getItem("guestModal"))) {
        this.$bvModal.show("intro-modal");
        localStorage.setItem("guestModal", true);
      }
      console.log(this.user.roles, "here");
    }
  },

  computed: {
    ...mapGetters({
      selectedLang: "Setting/langState",
      langsOptions: "Setting/langOptionState",
      colors: "Setting/colorState",
      user: "Auth/getCurrentUser",
    }),
  },
  watch: {},
  // sidebarTicket
  data() {
    return {
      userName: "",
      horizontal: false,
      apiUrl: process.env.VUE_APP_API,
      mini: false,
      darkMode: false,
      animated: { enter: "zoomIn", exit: "zoomOut" },
      animateClass: [
        { value: { enter: "zoomIn", exit: "zoomOut" }, text: "Zoom" },
        { value: { enter: "fadeInUp", exit: "fadeOutDown" }, text: "Fade" },
        {
          value: { enter: "slideInLeft", exit: "slideOutRight" },
          text: "Slide",
        },
        {
          value: { enter: "rotateInDownLeft", exit: "rotateOutDownLeft" },
          text: "Roll",
        },
      ],
      horizontalMenu: HorizontalItems,
      verticalMenu: SideBarItems,
      userProfile: profile,
      logo: loader,
      usersList: Users,
      rtl: false,
    };
  },
  methods: {
    beforeunloadFn(e) {
      const remember = JSON.parse(window.localStorage.getItem("remember"));
      if (!remember) {
        window.localStorage.setItem("token", null);
      }
    },
    updateRadio() {
      this.horizontal = this.$store.getters["Setting/horizontalMenuState"];
      this.mini = this.$store.getters["Setting/miniSidebarState"];
    },
    sidebarHorizontal() {
      this.$store.dispatch("Setting/horizontalMenuAction");
      this.updateRadio();
    },
    sidebarMini() {
      xray.triggerSet();
      this.$store.dispatch("Setting/miniSidebarAction");
      this.updateRadio();
    },
    rtlChange() {
      if (this.rtl) {
        this.rtlRemove();
      } else {
        this.rtlAdd();
      }
    },
    changeColor(code) {
      document.documentElement.style.setProperty("--iq-primary", code.primary);
      document.documentElement.style.setProperty(
        "--iq-primary-light",
        code.primaryLight
      );
      if (this.darkMode) {
        document.documentElement.style.setProperty(
          "--iq-bg-dark-color",
          code.bodyBgDark
        );
      } else {
        document.documentElement.style.setProperty(
          "--iq-bg-light-color",
          code.bodyBgLight
        );
      }
    },
    reset() {
      this.changeColor({
        primary: "#827af3",
        primaryLight: "#b47af3",
        bodyBgLight: "#efeefd",
        bodyBgDark: "#1d203f",
      });
      this.animated = { enter: "zoomIn", exit: "zoomOut" };
      this.light();
    },
    logout() {
      localStorage.setItem("guestModal", false);
      this.$store.dispatch("Auth/logout");
      this.$router.push({ name: "greeting" });
    },
    langChange(lang) {
      this.langChangeState(lang);
      this.$i18n.locale = lang.value;
      document.getElementsByClassName("iq-show")[0].classList.remove("iq-show");
      if (lang.value === "ar") {
        this.rtlAdd(lang);
      } else {
        this.rtlRemove(lang);
      }
    },
    ...mapActions({
      langChangeState: "Setting/setLangAction",
      rtlAdd: "Setting/setRtlAction",
      rtlRemove: "Setting/removeRtlAction",
    }),
  },
};
</script>
<style>
@import url("../assets/css/custom.css");

.iq-card {
  opacity: 0.9;
}

.guest-page {
  overflow: hidden;
  padding: 120px 15px 0;
  min-height: 100vh;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  background: #eff7f8;
  border-radius: 0 25px 25px 0;
}

.content-page {
  margin-left: 260px;
  overflow: hidden;
  padding: 120px 15px 0;
  min-height: 100vh;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  background: #eff7f8;
  border-radius: 25px 0 0 25px;
}
</style>
