<script>
import Table from "./EditableTable";
import Loader from "../../components/xray/Loader";
import ApiService from "../../Utils/axios";
import { mapGetters } from "vuex";
export default {
  name: "guide-list",
  components: { Table, Loader },
  computed: {
    ...mapGetters({ user: "Auth/getCurrentUser" }),
  },
  mounted() {
    document.title = `${this.$t("pageTitles.guides")} - Auditor`;
  },
  beforeMount() {
    this.fetchList();
  },
  data() {
    return {
      list: [],
      modalMsg: "",
      done: false,
      item: null,
      columns: [
        { editable: true, deleteable: true },
        { label: "Id", key: "id", class: "text-center" },
        { label: "Référence", key: "reference", class: "text-center" },
        { label: "Intitulé", key: "title", class: "text-left" },
        { label: "Category", key: "category.title", class: "text-left" },
        { label: "Statut", key: "status", class: "text-left" },
        { label: "Création", key: "created_at", class: "text-left" },
        {
          label: "Action",
          key: "action",
          class: "text-center",
        },
      ],
    };
  },
  methods: {
    remove(item) {
      let api = new ApiService();
      api
        .delete("/api/guide/" + item.id)
        .then((res) => {
          this.fetchList();
          this.$store.dispatch("Alert/activate", {
            text: "Guide supprimé avec succée",
            type: "success",
          });
        })
        .catch(() => {
          this.$store.dispatch("Alert/activate", {
            text: "Le guide ne peut pas être supprimé parce qu'il est utilisé par d'autres entités du système",
            type: "danger",
          });
        });
    },
    onRemove(item) {
      this.item = item;
      this.modalMsg = item.title;
      this.$bvModal.show("modal-1");
    },
    fetchList() {
      this.done = false;
      new ApiService()
        .get("/api/guide")
        .then((res) => {
          const data = Array.isArray(res.data) ? res.data : res.data.data;
          this.list = data.sort((a, b) => a.reference - b.reference);
          this.done = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<template>
  <div>
    <b-modal id="modal-1" title="Attention!" @ok="remove(item)">
      <p class="my-4">
        Etes vous sure de vouloir supprimer l'établissement
        <span class="bold text-danger">{{ modalMsg }}</span> ?
      </p>
    </b-modal>
    <Table
      tableTitle="Liste des guides"
      v-if="done && list.length"
      :addBtn="{
        text: $t('buttons.add'),
        link: '/guide/create',
      }"
      :rows="list"
      :cols="columns"
    />

    <b-container
      v-if="done && !list.length"
      style="height: 75vh"
      class="text-center"
      fluid
      ><h5>Aucun guide trouvé dans notre base de données.</h5>
      <b-button
        class="mt-3"
        variant="primary"
        :to="'/' + $i18n.locale + '/guide/create'"
        ><i class="fas fa-plus"></i> Ajouter un guide
      </b-button>
    </b-container>
    <Loader v-if="!done" />
  </div>
</template>
